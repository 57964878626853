import React from "react";
import "./../styles/About.css";

function About() {
  return (
    <div className="about">
      {/* Section principale */}
      <p className="about-intro">
        Une solution complète et innovante pour simplifier la gestion de vos commandes et maximiser vos profits.
      </p>

      {/* Liste des fonctionnalités */}
      <div className="about-features">
        <h3>La solution tout-en-un : 3 applications pour chaque acteur</h3>
        <div className="restaurateurs-benefits">
        <div className="benefit">
          📱 <h4>Application pour les clients :</h4><p>Profitez d'une interface intuitive pour parcourir le menu, personnaliser vos plats, 
        passer commande en quelques clics, et suivre l'évolution de votre commande en temps réel. 
        </p>
        </div>
        <div className="benefit">
          👨‍🍳 <h4>Application pour les restaurateurs :</h4><p>Accédez à une plateforme spécialement conçue pour simplifier la gestion des commandes. Recevez les demandes en direct et offrez un service rapide et personnalisé.</p>
        </div>
        <div className="benefit">
          🛠️ <h4>Application pour la gestion :</h4> <p>Notre application Web de gestion permet à nos employés de bénéficier d'outils avancés pour superviser l'ensemble du processus.</p>
        </div>
        </div>
      </div>

      {/* Section pour les restaurateurs */}
      <div className="about-restaurateurs">
        <h3 className="restaurateurs-title">Pourquoi choisir Bonn'App en tant que restaurateur ?</h3>
        <div className="restaurateurs-benefits">
          <div className="benefit">
            <h4>🎉 Une année sans frais (2025)</h4>
            <p>
              En 2025, profitez d'un démarrage sans risque avec <strong>0 % de frais</strong>. Maximisez vos bénéfices
              tout en testant les nombreux avantages de notre plateforme.
            </p>
          </div>
          <div className="benefit">
            <h4>💰 Une commission trés faible dès 2026</h4>
            <p>
              À partir de 2026, bénéficiez d'une commission de seulement <strong>5 %</strong>, l'une des plus avantageuses
              du marché. Cela vous permet de conserver <strong>95 % de vos revenus</strong>, sans compromis sur vos marges.
            </p>
          </div>
          <div className="benefit">
          <h4>🎁 Nous récompensons vos clients</h4>
            <p>
              Ils bénéficieront de <strong>réductions</strong> sur des billets de cinéma, du bowling et d'autres loisirs. En bref, nous leur offrirons des avantages qu'ils adoreront.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
