import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/laranaver.JPG"; // Remplacez ce chemin par le chemin correct vers votre logo
import "./../styles/Header.css";

function Header() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false); // État pour basculer le menu

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Bonn'App Logo" className="logo-img" />
      </div>
      <button
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={() => setMenuOpen(!menuOpen)}
        aria-label="Toggle navigation"
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
      <nav className={`nav ${menuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => setMenuOpen(false)} // Fermer le menu après clic
            >
              Accueil
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={location.pathname === "/about" ? "active" : ""}
              onClick={() => setMenuOpen(false)} // Fermer le menu après clic
            >
              Notre App
            </Link>
          </li>
          <li>
            <Link
              to="/partners"
              className={location.pathname === "/partners" ? "active" : ""}
              onClick={() => setMenuOpen(false)} // Fermer le menu après clic
            >
              Devenir Partenaire
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active" : ""}
              onClick={() => setMenuOpen(false)} // Fermer le menu après clic
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;



