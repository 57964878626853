import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./../styles/Partners.css";

function Partners() {
  const [formData, setFormData] = useState({
    restaurantName: "",
    googleLink: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a51nbbj", // Remplacez par votre Service ID
        "template_b6m1lvl", // Remplacez par votre Template ID
        e.target, // Le formulaire envoyé
        "66tGrDX6BFnby31QI" // Remplacez par votre Public Key
      )
      .then(
        (result) => {
          alert("Demande envoyée avec succès, nous vous recontacterons rapidement.");
        },
        (error) => {
          alert("Une erreur est survenue. Veuillez réessayer.");
        }
      );

    setFormData({
      restaurantName: "",
      googleLink: "",
      phone: "",
      email: "",
    });
  };

  return (
    <div className="partners">
      <div className="partners-container">
        {/* Section d'informations */}
        <div className="partners-info">
          <h2>Devenir Partenaire</h2>
          <p>
            Rejoignez notre réseau de restaurateurs et bénéficiez d'une
            plateforme innovante pour gérer vos commandes et atteindre de
            nouveaux clients. Nous vous contacterons rapidement après votre
            demande.
          </p>
          <div className="partners-details">
            <div className="partners-detail">
              💸 <span>0% de frais pour toute l'année 2025.</span>
            </div>
            <div className="partners-detail">
              📉 <span>Une commission de seulement 5% dès 2026.</span>
            </div>
            <div className="partners-detail">
              🎟️{" "}
              <span>
                Nous offrons à vos clients des réductions exclusives sur leurs
                tickets de cinéma, avec d'autres avantages à venir.
              </span>
            </div>
          </div>
        </div>

        {/* Formulaire */}
        <div className="partners-form">
          <h3>Demande d'inscription</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="restaurantName"
                placeholder="Nom du restaurant"
                value={formData.restaurantName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="url"
                name="googleLink"
                placeholder="Lien Google"
                value={formData.googleLink}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phone"
                placeholder="Numéro de téléphone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Adresse email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="submit-btn">
              📩 Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Partners;


