import React, { useState } from 'react';
import "./../styles/Contact.css";
import emailjs from 'emailjs-com';
import { 
  Mail, 
  Phone, 
  MapPin, 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin,
  Send,
  User,
  MessageCircle
} from 'lucide-react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_a51nbbj', 'template_6j02j6t', e.target, '66tGrDX6BFnby31QI')
      .then(
        (result) => {
          alert('Message envoyé avec succès !');
        },
        (error) => {
          alert('Une erreur est survenue, veuillez réessayer.');
        }
      );
    setFormData({ name: '', email: '', message: '' });
  };
  

  return (
    <div className="contact">
      <div className="contact-container">
        <div className="contact-info">
          <h2>Contactez Bonn'App</h2>
          <p className="contact-subtitle">
            Nous sommes à votre écoute. N'hésitez pas à nous contacter pour toute question ou suggestion.
          </p>

          <div className="contact-details">
            <div className="contact-detail">
              <Mail className="contact-icon" />
              <div>
                <h4>Email</h4>
                <p>ilias.abidli@outlook.com</p>
              </div>
            </div>

            <div className="contact-detail">
              <Phone className="contact-icon" />
              <div>
                <h4>Téléphone</h4>
                <p>+32 471 65 56 98</p>
              </div>
            </div>

            <div className="contact-detail">
              <MapPin className="contact-icon" />
              <div>
                <h4>Adresse</h4>
                <p>Rue Grande 38, 7330 Saint-Ghislain, Belgique</p>
              </div>
            </div>
          </div>

          <div className="social-links">
            <h4>Suivez-nous (bientôt)</h4>
            <div className="social-icons">
              <a href="#" className="social-icon"><Facebook /></a>
              <a href="#" className="social-icon"><Twitter /></a>
              <a href="#" className="social-icon"><Instagram /></a>
              <a href="#" className="social-icon"><Linkedin /></a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <h3>Envoyez-nous un message</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <User className="form-icon" />
              <input 
                type="text" 
                name="name"
                placeholder="Votre nom"
                value={formData.name}
                onChange={handleChange}
                required 
              />
            </div>

            <div className="form-group">
              <Mail className="form-icon" />
              <input 
                type="email" 
                name="email"
                placeholder="Votre email"
                value={formData.email}
                onChange={handleChange}
                required 
              />
            </div>

            <div className="form-group">
              <MessageCircle className="form-icon" />
              <textarea 
                name="message"
                placeholder="Votre message"
                value={formData.message}
                onChange={handleChange}
                required 
              ></textarea>
            </div>

            <button type="submit" className="submit-btn">
              <Send className="btn-icon" />
              Envoyer le message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
